<template>
  <div class="terms-consent mt-5">
    <div class="card-container px-5">
      <div class="terms-consent-title primary-gradient--bg pa-5">
        <div class="terms-consent-head white--text">
          <div class="text-18 font-500">เงื่อนไขและข้อตกลงการใช้บัตรสมาชิก</div>
          <div class="line-h-1 text-20 font-700">{{ titleByDomain }}</div>
        </div>
      </div>
      <div class="card-group pb-5 mb-10 px-5">
        <div class="terms-consent-detail">
          <div class="terms-consent-body py-5">
            <div
              v-html="message.consents.content"
              style="overflow-wrap: break-word"
              class="privilege text-wrap font-500"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "terms-consent",
  components: {},
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({}),
  computed: {
    titleByDomain() {
      if (this.message.consents.id === "be") {
        return "Be Phyathai 3 Family";
      } else if (this.message.consents.id === "prestige") {
        return "Long Term Prestige";
      } else if (this.message.consents.id === "all-you") {
        return "All You Can Check";
      } else if (this.message.consents.id === "pdpa") {
        return "PDPA";
      } else if (this.message.consents.id === "marketing") {
        return "Marketing";
      } else {
        return "Mommy & Baby Club";
      }
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.card-container {
  padding: 0px 1.8rem;
}

.privilege {
  color: #757575;
}
.card-group {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  border-radius: 0px 0px 20px 20px;
}

.terms-consent-body {
  color: #757575;
}

.terms-consent-title {
  box-shadow: 0px 4px 20px rgba(170, 170, 170, 0.5);
  border-radius: 20px 20px 0px 0px;
}
</style>
