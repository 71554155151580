<template>
  <div class="otp-form">
    <v-form>
      <v-container class="otp px-8">
        <div class="mt-5 mb-2 text-center font-400 text-14 text-gray">
          รหัส OTP มีอายุการใช้งาน 3 นาที
        </div>
        <div class="mb-4 text-center font-400 text-14 text-gray">Ref code:{{ myRef }}</div>
        <v-otp-input
          class="font-700 text-22"
          :disabled="loading"
          length="6"
          ref="otpInput"
          type="number"
          @finish="submit()"
          :should-auto-focus="true"
          :is-input-num="true"
          v-model="token"
        ></v-otp-input>
        <span v-show="isInvalidOTP" class="error--text">รหัส OTP ผิด</span>
        <div class="text-center mt-5 ref-text">
          <v-btn
            class="white--text font-500 text-16 px-7 py-5"
            rounded
            color="#235D3A"
            @click="submit()"
            :disabled="timerCount < 0"
          >
            ยืนยันการเปลี่ยนแปลง
          </v-btn>
          <div>
            <div class="mt-5 text-center font-400 text-14 text-gray">
              ไม่ได้รับรหัส OTP? ขออีกครั้งใน
              <span v-show="timerCount > 0"
                >0{{ ~~(timerCount / 60) }}:{{
                  (timerCount % 60).toString().padStart(2, "0")
                }}</span
              >
            </div>
            <v-btn
              text
              :disabled="timerCount > 0"
              @click="reSendOTP()"
              class="mt-1 text-decoration-underline again-color"
            >
              ขอรหัส OTP อีกครั้ง
            </v-btn>
          </div>
        </div>
      </v-container>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "otpform",
  props: {
    isInvalidOTP: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    refCode: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    timerEnabled: true,
    timerCount: 180,
    token: "",
  }),
  computed: {
    myRef() {
      return this.refCode;
    },
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--;
        }, 1000);
      }
    },

    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true,
    },
    isInvalidOTP(status) {
      if (status) {
        this.token = "";
      }
    },
  },
  created() {
    this.timerEnabled = true;
  },

  methods: {
    submit() {
      this.$emit("submit", this.token);
    },
    reSendOTP() {
      this.$emit("resend");
      this.timerCount = 180;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field--is-booted {
  border-radius: 12px !important;
}
.again-color {
  color: #235d3a;
}

.text-gray {
  color: #757575;
}
.ref-text {
  font-size: 12px;
  color: #58595b;
}
</style>
